import { ShopItem } from "./ShopItem";

interface ShopsProps {
  shops: any;
}

export const Shops = ({ shops }: ShopsProps) => {
  const level = 0;
  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="d-flex flex-column"
    >
      <h6>Sklepy z obecną ofertą:</h6>
      <div
        style={{ width: "100%" }}
        className="d-flex flex-column justify-content-between"
      >
        {shops.map((shop: any) => {
          return <ShopItem shop={shop} level={level} />;
        })}
      </div>
    </div>
  );
};
