import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/app/appSlice";
import newOfferReducer from "../features/app/newOfferSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    newOffer: newOfferReducer,
  },
});
