import React, { useEffect, useState } from "react";
import "./Suppliers.scss";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

interface SuppliersProps {
  items: any;
  onChange: any;
}

export const Suppliers = ({ items, onChange }: SuppliersProps) => {
  const [allItems, setAllItems] = useState<any>(items); // all items
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedItems2, setSelectedItems2] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchValue2, setSearchValue2] = useState<string>("");

  useEffect(() => {
    if (searchValue !== "") {
      const allItemsCopy = items.filter((item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setAllItems(allItemsCopy);
    } else {
      setAllItems(items);
    }

    if (searchValue2 !== "") {
      const selectedItemsCopy = selectedItems.filter((item: any) =>
        item.name.toLowerCase().includes(searchValue2.toLowerCase())
      );
      setSelectedItems(selectedItemsCopy);
    } else {
      setSelectedItems(selectedItems2);
    }
  }, [searchValue, searchValue2]);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems]);

  return (
    <div className="suppliers">
      <div className="select-columns">
        <div className="all-items">
          <h4>Wszystkie</h4>
          <input
            type="text"
            placeholder="Wyszukaj..."
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {allItems.map((item: any, key: number) => {
            return (
              <div
                className="item"
                key={key}
                onClick={() => {
                  setSelectedItems([...selectedItems, item]);
                  setSelectedItems2([...selectedItems2, item]);
                  // remove item from allItems
                  setAllItems(allItems.filter((el: any) => el.id !== item.id));
                }}
              >
                {item.name}
                <AiOutlineArrowRight />
              </div>
            );
          })}
        </div>
        <div className="selected-items">
          <h4>Wybrane</h4>
          <input
            type="text"
            placeholder="Wyszukaj..."
            onChange={(e) => {
              setSearchValue2(e.target.value);
            }}
          />
          {selectedItems.map((item: any, key: number) => {
            return (
              <div
                className="item"
                key={key}
                onClick={() => {
                  setSelectedItems(
                    selectedItems.filter((el: any) => el.id !== item.id)
                  );
                  setSelectedItems2(
                    selectedItems2.filter((el: any) => el.id !== item.id)
                  );
                  // add item to allItems
                  setAllItems([...allItems, item]);
                }}
              >
                <AiOutlineArrowLeft />
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
