import { useEffect, useRef, useState } from "react";
import "./NumberCell.scss";
import { NumberWithSpace } from "../../../../Functions/NumberWithSpace";

interface NumberCellProps {
  promo: number;
  nonPromo: number;
  max: number;
}

export const NumberCell = ({ promo, nonPromo, max }: NumberCellProps) => {
  const [widthPromo, setWidthPromo] = useState(0);
  const [widthNonPromo, setWidthNonPromo] = useState(0);

  const getWidthOfBoth = (sum: number) => {
    return (sum * 100) / max;
  };

  const getPercentVal = (value: number) => {
    return (value * 100) / max;
  };

  const sum = NumberWithSpace(promo + nonPromo);

  useEffect(() => {
    setTimeout(() => {
      setWidthPromo(getPercentVal(promo));
      setWidthNonPromo(getPercentVal(nonPromo));
    }, 500);
  }, []);
  return (
    <td className="number-custom-cell">
      {/* promo */}
      <div
        style={{
          background: `hsl(0, 50%, 50%)`,
          height: "15%",
          bottom: 0,
          width: widthPromo + "%",
          left: 0,
        }}
        className="colored-cell"
      ></div>
      {/* promo */}
      <div
        style={{
          background: `hsl(38, 50%, 50%)`,
          height: "15%",
          bottom: 0,
          width: widthNonPromo + "%",
          left: widthPromo + "%",
        }}
        className="colored-cell"
      ></div>
      <div className="cell-value">{sum}</div>
    </td>
  );
};
