import { useEffect, useState } from "react";
import "./App-light.scss";
import "./App-dark.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  FcSettings,
  FcBarChart,
  FcCloseUpMode,
  FcExport,
  FcFilledFilter,
  FcSynchronize,
} from "react-icons/fc";
import { TopBar } from "./components/TopBar/TopBar";
import { LeftMenu } from "./components/LeftMenu/LeftMenu";
import { Timeline } from "./components/Timeline/Timeline";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { LeftSideComponent } from "./components/Modals/LeftSideComponent";
import { RightSideComponent } from "./components/Modals/RightSideComponent";
import { GrDocumentVerified } from "react-icons/gr";
import { BsCloudArrowUp, BsLock, BsUnlock } from "react-icons/bs";
import { RiMailSendLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "./env";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [showFormModal, setShowFormModal] = useState(false);
  const [formConfig, setFormConfig] = useState<any>(null);
  const [activeModalItem, setActiveModalItem] = useState<any>(null);
  const [completeForm, setCompleteForm] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(true);

  useEffect(() => {
    const bodyEl = document.querySelector("body");
    if (bodyEl) {
      if (darkMode) {
        bodyEl.classList.remove("light-mode");
        bodyEl.classList.add("dark-mode");
      } else {
        bodyEl.classList.remove("dark-mode");
        bodyEl.classList.add("light-mode");
      }
    }
  }, [darkMode]);

  useEffect(() => {
    if (showFormModal && !formConfig) {
      axios.get(API_URL + "/Offers/getOfferForm").then((res) => {
        const copyRes = { ...res.data };
        setFormConfig(copyRes);
        setActiveModalItem(0);
        const modalEl: any = document.querySelector(".my-modal");
        if (modalEl) {
          modalEl.style.top = "50%";
        }
      });
    } else {
      // const modalEl: any = document.querySelector(".my-modal");
      // if (modalEl) {
      //   modalEl.style.top = "-100%";
      // }
    }
    //eslint-disable-next-line
  }, [showFormModal]);

  useEffect(() => {
    if (formConfig && formConfig.parameters.length > 0) {
      formConfig.parameters.forEach((item: any, index: number) => {
        if (item.validationError !== null) {
          setCompleteForm(false);
        } else {
          setCompleteForm(true);
        }
      });
    }
  }, [formConfig]);

  const sendOffer = () => {
    const copyFormConfig = { ...formConfig };
    copyFormConfig.validateOnly = false;

    const body = {
      offerName: copyFormConfig.parameters[2].value,
      IsUnlocked: isUnlocked,
      OfferFrom: copyFormConfig.parameters[3].dateFrom,
      OfferTo: copyFormConfig.parameters[3].dateTo,
      Details: "",
      OfferImage: "",
      Sector: copyFormConfig.parameters[1].value,
      DateFromSale: copyFormConfig.parameters[4].dateFrom,
      DateToSale: copyFormConfig.parameters[4].dateTo,
      OfferTypeId: copyFormConfig.parameters[0].value,
      OfferInfo: copyFormConfig.parameters[6].value,
    };

    console.log(JSON.stringify(body));
    // axios
    //   .post(API_URL + "/Offers/postOfferForm", JSON.stringify(copyFormConfig), {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res.data.status.success) {
    //       setShowFormModal(false);
    //       setFormConfig(null);
    //       toast.success(res.data.status.message, { type: "success" });
    //     }
    //   });
  };
  return (
    <div
      className={`App d-flex flex-column ${
        darkMode ? "dark-mode" : "light-mode"
      }`}
    >
      {showFormModal && (
        <div className="my-modal">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className="icon-own"
                style={{ marginRight: "10px", backgroundColor: "#2ba7f3" }}
              >
                <GrDocumentVerified color={"white"} />
              </div>
              <h4>Edycja ofertowania dla dostawcy</h4>
            </div>
            <div className="d-flex">
              {isUnlocked ? (
                <div
                  className="icon-own"
                  style={{ marginRight: "10px", backgroundColor: "#00bc8c" }}
                  onClick={() => {
                    setIsUnlocked(false);
                  }}
                >
                  <BsUnlock color={"white"} size={21} />
                </div>
              ) : (
                <div
                  className="icon-own"
                  style={{ marginRight: "10px", backgroundColor: "#e85434" }}
                  onClick={() => {
                    setIsUnlocked(true);
                  }}
                >
                  <BsLock color={"white"} size={21} />
                </div>
              )}

              <div
                className="icon-own"
                style={{ marginRight: "10px", backgroundColor: "#2ba7f3" }}
              >
                <BsCloudArrowUp color={"white"} size={21} />
              </div>
              <div
                className="icon-own"
                style={{ marginRight: "10px", backgroundColor: "#f09300" }}
              >
                <RiMailSendLine color={"white"} size={21} />
              </div>
              <div
                className="modal-close"
                onClick={() => {
                  setShowFormModal(false);
                  setFormConfig(null);
                }}
              >
                <IoClose size={25} />
              </div>
            </div>
          </div>
          <hr />

          <div
            className="modal-container"
            style={{
              flexGrow: 1,
              overflow: "hidden",
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateColumns: "250px 1fr",
            }}
          >
            <div style={{ overflow: "auto" }} className={`d-flex flex-column`}>
              {formConfig &&
                formConfig.parameters.map((item: any, key: number) => {
                  let selectedValue = "";
                  if (item.parameterType === "select") {
                    selectedValue =
                      item.items && item.items[item.value]
                        ? item.items[item.value].name
                        : "";
                  } else if (item.parameterType === "selectMulti") {
                    selectedValue = `Ilość: ${item.values.length}`;
                  }
                  return (
                    <div
                      className="modal-item-summary"
                      key={key}
                      onClick={() => {
                        setActiveModalItem(key);
                      }}
                    >
                      <LeftSideComponent
                        valid={item.validationError === null ? true : false}
                        label={item.label}
                        active={activeModalItem === key ? true : false}
                        selectedValue={selectedValue}
                      />
                    </div>
                  );
                })}
            </div>
            {activeModalItem !== null ? (
              <div style={{ marginLeft: "15px" }}>
                <RightSideComponent
                  item={formConfig.parameters[activeModalItem]}
                  formConfig={formConfig}
                  setFormConfig={setFormConfig}
                  activeModalItem={activeModalItem}
                  validationError={
                    formConfig.parameters[activeModalItem].validationError
                  }
                  setActiveModalItem={setActiveModalItem}
                  completeForm={completeForm}
                  sendOffer={sendOffer}
                />
              </div>
            ) : // <div className="col-6">
            //   {" "}
            //   {formConfig && (
            //     <button
            //       className="btn btn-primary bg-orange mt-2"
            //       style={{
            //         width: "20%",
            //         alignSelf: "end",
            //       }}
            //     >
            //       Dodaj
            //     </button>
            //   )}{" "}
            // </div>
            null}
          </div>
          <div className="modal-header d-flex flex-column"></div>
        </div>
      )}
      <TopBar
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setCompareMode={setCompareMode}
        compareMode={compareMode}
      />
      <div
        className="d-flex justify-content-between"
        style={{ minHeight: "100vh", overflow: "hidden" }}
      >
        <div className="side-menu">
          <LeftMenu />
        </div>
        <div className="content">
          {compareMode ? (
            <>
              <Timeline
                setShowFormModal={setShowFormModal}
                compareMode={compareMode}
              />
              <Timeline
                setShowFormModal={setShowFormModal}
                compareMode={compareMode}
              />
            </>
          ) : (
            <Timeline
              setShowFormModal={setShowFormModal}
              compareMode={compareMode}
            />
          )}
        </div>
        <div className="right-menu">
          <div className="d-flex flex-column" style={{ cursor: "pointer" }}>
            <FcBarChart size={23} className="m-2" />
            <FcCloseUpMode size={23} className="m-2" />
            <FcExport size={23} className="m-2" />
            <FcFilledFilter size={23} className="m-2" />
            <FcSettings size={23} className="m-2" />
            <FcSynchronize size={23} className="m-2" />
          </div>
        </div>
      </div>
      <ToastContainer theme={darkMode ? "dark" : "light"} />
    </div>
  );
}

export default App;
