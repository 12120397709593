// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./Columns.css";

interface ColumnsProps {
  columns: any;
  setColumns: (columns: any) => void;
}

export const Columns = ({ columns, setColumns }: ColumnsProps) => {
  return (
    <div>
      <div style={{ padding: "8px" }}>
        <input
          type="text"
          placeholder="Szukaj..."
          style={{
            width: "100%",
            backgroundColor: "#333",
            color: "#eee",
            border: "none",
            padding: "6px 12px",
            outline: "none",
            borderRadius: "50px",
          }}
          onChange={(e) => {
            const value = e.target.value;
            columns.map((column: any) => {
              const name = column.headerName.split("%--%")[0];
              if (name.toLowerCase().includes(value.toLowerCase())) {
                column.show = true;
              } else {
                column.show = false;
              }
            });
            setColumns([...columns]);
          }}
        />
      </div>
      {columns.map((column: any, key: number) => {
        const name = column.headerName.split("%--%")[0];
        if (column.show) {
          return (
            <div
              key={key}
              onClick={() => {
                column.hide = !column.hide;
                setColumns([...columns]);
              }}
              style={{
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: "0",
                color: column.hide ? "#888" : "#eee",
                cursor: "pointer",
              }}
              className="column-right-menu"
            >
              <div style={{ padding: "4px" }}>
                {column.hide ? (
                  <input type="checkbox" checked={false} />
                ) : (
                  <input type="checkbox" checked={true} />
                )}
              </div>
              <div>{name}</div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
