import "./../../App-light.scss";
import "./../../App-dark.scss";
// import "./../App-dark.scss";
import { useEffect, useRef, useState } from "react";
import {
  AiOutlineShop,
  AiOutlineBars,
  AiOutlineLineChart,
} from "react-icons/ai";
import { BiColumns, BiSearchAlt, BiWallet } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { Shops } from "./RightSideComponents/Shops/Shops";
import { IoMdStats } from "react-icons/io";
import { MonthsComponent } from "./RightSideComponents/StatsComponents/MonthsComponent";
import { WeeksComponent } from "./RightSideComponents/StatsComponents/WeeksComponent";
import { TrackBars } from "./RightSideComponents/TrackBars/TrackBars";
import { Columns } from "./RightSideComponents/Columns/Columns";
import "./Offer.scss";
import { Budgets } from "./RightSideComponents/Budgets/Budgets";
import axios from "axios";
import { MainChart } from "./LeftSideComponents/Charts/MainChart";
import { NumberWithSpace } from "../Functions/NumberWithSpace";

export const Offer = () => {
  const [gridUrl, setGridUrl] = useState<string>("/grid/#/1234/");
  const [selected, setSelected] = useState<any>(null);
  const [activeMenu, setActiveMenu] = useState<string>("");
  const [columnsDef, setColumnsDef] = useState<any>([]);
  const [activeColor, setActiveColor] = useState<string>("");
  const [activeTitle, setActiveTitle] = useState<string>("");
  const [menuHidden, setMenuHidden] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [weeksData, setWeeksData] = useState<any[]>([]);
  const [monthsData, setMonthsData] = useState<any[]>([]);
  const [showChart, setShowChart] = useState<boolean>(false);

  const iframeRef = useRef(null);
  const rightContentRef = useRef(null);

  const shops = [
    {
      name: "Sklep 1",
      id: 1,
      checked: false,
      children: [
        { name: "Sklep 2", id: 1, checked: false },
        { name: "Sklep 3", id: 1, checked: true },
        { name: "Sklep 4", id: 1, checked: false },
      ],
    },
    {
      name: "Sklep 5",
      id: 1,
      checked: false,
      children: [
        { name: "Sklep 6", id: 1, checked: true },
        { name: "Sklep 7", id: 1, checked: false },
        { name: "Sklep 8", id: 1, checked: false },
      ],
    },
  ];

  function getIframeWindow(iframe_object: any) {
    let doc;

    if (iframe_object.contentWindow) {
      return iframe_object.contentWindow;
    }

    if (iframe_object.window) {
      return iframe_object.window;
    }

    if (!doc && iframe_object.contentDocument) {
      doc = iframe_object.contentDocument;
    }

    if (!doc && iframe_object.document) {
      doc = iframe_object.document;
    }

    if (doc && doc.defaultView) {
      return doc.defaultView;
    }

    if (doc && doc.parentWindow) {
      return doc.parentWindow;
    }

    return undefined;
  }

  const setIframeTarget = () => {
    const iframeElement: any = document.getElementById("gridIframe");
    if (iframeElement) {
      const iframeWindow = getIframeWindow(iframeElement);
      if (iframeWindow) {
        iframeWindow.document.addEventListener("click", (e: any) => {
          setTimeout(() => {
            const selected = iframeWindow.document.getSelected();
            setSelected(selected);
          }, 50);
          return false;
        });
      }
    }
  };

  const getColumns = () => {
    const iframeElement: any = document.getElementById("gridIframe");
    setTimeout(() => {
      if (iframeElement) {
        const iframeWindow = getIframeWindow(iframeElement);
        const defs = iframeWindow.document.getColumnsDef();

        if (defs && defs.length > 0) {
          defs.forEach((def: any) => {
            def.children.forEach((child: any) => {
              const body = {
                colId: child.colId,
                headerName: child.headerName,
                hide: child.hide,
                show: true,
              };
              setColumnsDef((prev: any) => [...prev, body]);
            });
          });
        } else {
          getColumns();
        }
      }
    }, 500);
  };

  useEffect(() => {
    // filter show columns
    const showColumns = columnsDef.filter((column: any) => !column.hide);
    const hideColumns = columnsDef.filter((column: any) => column.hide);

    let trueToGrid: any = [];
    let falseToGrid: any = [];

    showColumns.forEach((column: any) => {
      trueToGrid.push(column.colId);
    });

    hideColumns.forEach((column: any) => {
      falseToGrid.push(column.colId);
    });
    // run function with showed columns in grid
    const iframeElement: any = document.getElementById("gridIframe");
    if ((iframeElement && showColumns.length > 0) || hideColumns.length > 0) {
      const iframeWindow = getIframeWindow(iframeElement);
      iframeWindow.document.setColumnsDef(trueToGrid, falseToGrid);
    }
  }, [columnsDef]);

  useEffect(() => {
    if (selected && selected.length > 0) {
      axios
        .post(
          "https://fafciax.dyndns.org:44380/wsgrid/ws/grid/sidebar?sessionId=1234",
          selected[0]
        )
        .then((res) => {
          setColumns(res.data.columns);
          setMonthsData(res.data.data1);
          setWeeksData(res.data.data2);
        });
    }
  }, [selected]);

  return (
    <div
      className="App d-flex flex-row dark-mode justify-content-between offer-right-menu"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column"
        style={{
          width: "100%",
          height: "100vh",
          transition: "all 1s ease-in-out",
        }}
      >
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <iframe
            ref={iframeRef}
            id="gridIframe"
            name="gridIframe"
            src={gridUrl}
            title="Grid"
            onLoad={() => {
              setIframeTarget();
              getColumns();
            }}
            frameBorder="0"
            style={{
              overflow: "hidden",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
            height="100%"
            width={"100%"}
          />
          <div
            style={{
              position: "absolute",
              bottom: "25px",
              right: "25px",
              borderRadius: "50%",
              backgroundColor: "#333",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            className="searcher"
          >
            <BiSearchAlt size={21} />
          </div>
        </div>
        <div
          style={{
            transition: "all 0.5s ease-in-out",
            height: showChart ? "1000px" : "0px",
            maxHeight: showChart ? "100vh" : "0",
          }}
        >
          <MainChart />
        </div>
      </div>
      <div
        style={{
          height: "100vh",
          fontSize: "11px",
          top: 0,
          transition: "all 0.5s ease-in-out",
          width: menuHidden ? `35px` : "17.5%",
        }}
      >
        <div className="d-flex" style={{ height: "100%" }}>
          <div
            style={{
              borderRight: "1px solid #444",
              borderLeft: "1px solid #444",
            }}
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <div>
              <div
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => {
                  setActiveMenu("shops");
                  setActiveColor("#1F86FB");
                  setActiveTitle("SKLEPY");
                }}
              >
                <AiOutlineShop
                  size={22}
                  color={activeMenu === "shops" ? "#1F86FB" : "#A3CCFB"}
                />
              </div>

              <div
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => {
                  setActiveMenu("stats");
                  setActiveColor("#AE33FC");
                  setActiveTitle("OBRÓT");
                }}
              >
                <IoMdStats
                  size={22}
                  color={activeMenu === "stats" ? "#AE33FC" : "#E4A7FF"}
                />
              </div>

              <div
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => {
                  setActiveMenu("trackbars");
                  setActiveColor("#FC2FD1");
                  setActiveTitle("TRACKBARS");
                }}
              >
                <AiOutlineBars
                  size={22}
                  color={activeMenu === "trackbars" ? "#FC2FD1" : "#FF97E9"}
                />
              </div>

              <div
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => {
                  setActiveMenu("columns");
                  setActiveColor("#FC9516");
                  setActiveTitle("KOLUMNY");
                }}
              >
                <BiColumns
                  size={22}
                  color={activeMenu === "columns" ? "#FC9516" : "#FCCD92"}
                />
              </div>
              <div
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => {
                  setActiveMenu("budget");
                  setActiveColor("#0AF717");
                  setActiveTitle("BUDŻETY");
                }}
              >
                <BiWallet
                  size={22}
                  color={activeMenu === "budget" ? "#0AF717" : "#A6FBAB"}
                />
              </div>
            </div>
            <div>
              <div
                className="d-flex justify-content-center p-1"
                style={{ borderTop: "1px solid #444", cursor: "pointer" }}
                onClick={() => {
                  setShowChart(!showChart);
                }}
              >
                <AiOutlineLineChart size={20} />
              </div>
              <div
                style={{
                  borderTop: "1px solid #444",
                  width: "100%",
                  padding: "1.5px 11px",
                  cursor: "pointer",
                  transition: "all 0.5s ease-in-out",
                }}
                onClick={() => {
                  setMenuHidden(!menuHidden);
                }}
              >
                <div
                  style={{
                    transition: "all 0.5s ease-in-out",
                    transform: `${
                      menuHidden ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                  }}
                >
                  <BsChevronRight />
                </div>
              </div>
            </div>
          </div>
          <div
            ref={rightContentRef}
            className="d-flex flex-column"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            {/* {selected && (
              <>
                <ul>
                  <li>{selected[0].A}</li>
                  <li>{selected[0].B}</li>
                  <li>{selected[0].C}</li>
                  <li>{selected[0].D}</li>
                  <li>{selected[0].E}</li>
                  <li>{selected[0].F}</li>
                </ul>
              </>
            )} */}
            {activeMenu === "shops" && <Shops shops={shops} />}
            {activeMenu === "stats" && (
              <div className="stats-container">
                {selected && columns && monthsData ? (
                  <div
                    style={{
                      maxHeight: "35vh",
                      overflow: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center" }}>MIESIĄCE:</h6>
                    <MonthsComponent columns={columns} data={monthsData} />
                  </div>
                ) : (
                  ""
                )}
                {selected && columns && weeksData ? (
                  <div
                    style={{
                      maxHeight: "65vh",
                      overflow: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center" }}>TYGODNIE:</h6>
                    <MonthsComponent columns={columns} data={weeksData} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {activeMenu === "trackbars" && <TrackBars />}

            {activeMenu === "columns" && (
              <Columns columns={columnsDef} setColumns={setColumnsDef} />
            )}

            {activeMenu === "budget" && <Budgets />}

            <div
              style={{
                textAlign: "center",
                borderTop: "2px solid #444",
                padding: "1.5px",
                color: activeColor,
                fontWeight: "bold",
              }}
            >
              {activeTitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
