import "./ContextMenu.css";

interface ContextMenuProps {
  show: boolean;
  x: number;
  y: number;
  item: any;
  setContextMenuData: any;
}

export const ContextMenu = ({
  show,
  x,
  y,
  item,
  setContextMenuData,
}: ContextMenuProps) => {
  return (
    <div
      className={`context-menu-own ${show ? "show-menu" : "hide-menu"}`}
      onMouseLeave={() => {
        setContextMenuData({
          show: false,
        });
      }}
      style={{
        position: "absolute",
        top: y + "px",
        left: x + "px",
        backgroundColor: "#111",
        borderRadius: "5px",
        zIndex: 5000,
      }}
    >
      <div className="item-menu">
        <a href="https://google.com" target="_blank">
          <div className="context-menu-item">Podgląd</div>
        </a>
      </div>
      <div className="item-menu">
        <a href="https://google.com" target="_blank">
          <div className="context-menu-item">Zadeklaruj</div>
        </a>
      </div>
      <div className="item-menu">
        <a href="https://google.com" target="_blank">
          <div className="context-menu-item">Usuń</div>
        </a>
      </div>
    </div>
  );
};
