import axios from "axios";
import "./RightSideComponent.scss";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import pl from "date-fns/locale/pl";
import { API_URL } from "../../env";
import { useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import { AiOutlineCalendar } from "react-icons/ai";
import moment from "moment";
import { Suppliers } from "./RightSideComponents/Suppliers";
registerLocale("pl", pl);

interface RightSideComponentProps {
  item: any;
  formConfig: any;
  setFormConfig: any;
  activeModalItem: number;
  validationError: any;
  setActiveModalItem: any;
  completeForm: any;
  sendOffer: any;
}

export const RightSideComponent = ({
  item,
  formConfig,
  setFormConfig,
  activeModalItem,
  validationError,
  setActiveModalItem,
  completeForm,
  sendOffer,
}: RightSideComponentProps) => {
  const copyFormConfig = { ...formConfig };
  const updateValue = (value: any, index: number, valueKey: string) => {
    copyFormConfig.parameters[index][valueKey] = value;
    copyFormConfig.parameters[5].items.length = 10;
    copyFormConfig.validateOnly = true;
    axios
      .post(API_URL + "/Offers/postOfferForm", JSON.stringify(copyFormConfig), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const newParameters = res.data.validationResult.parameters;
        copyFormConfig.parameters = newParameters;
        setFormConfig(copyFormConfig);
      });
  };

  const templateHtml = `
  <div>
    <h1>Test</h1>
    <p>Test</p>
  </div>
  `;

  return (
    <div className="right-side-item">
      <div className="label">{item.label}</div>

      <div className="content-right mt-4">
        {/* SELECT */}
        {item.parameterType === "select" ? (
          <div className="parent-select">
            {item.items.map((optionItem: any, key: number) => (
              <div
                className={`select-option ${
                  item.value === optionItem.id ? "active-option" : ""
                }`}
                key={key}
                style={{ cursor: "pointer", borderColor: optionItem.color }}
                onClick={() => {
                  updateValue(key.toString(), activeModalItem, "value");
                }}
              >
                {optionItem.name}
              </div>
            ))}
          </div>
        ) : null}

        {/* MULTISELECT */}
        {item.parameterType === "selectMulti" ? (
          <Suppliers
            items={item.items}
            onChange={(selectedItems) => {
              const selectedItemsIds = selectedItems.map(
                (item: any) => item.id
              );
              updateValue(selectedItemsIds, activeModalItem, "values");
            }}
          />
        ) : null}

        {/* TEXT PLAIN */}
        {item.parameterType === "text" && item.textType === "Plain" ? (
          <>
            <input
              type="text"
              className="form-control"
              defaultValue={item.value}
              onBlur={(e) => {
                updateValue(e.target.value, activeModalItem, "value");
              }}
            />
          </>
        ) : null}

        {/* TEXT Html */}
        {item.parameterType === "text" && item.textType === "Html" ? (
          <>
            <ReactQuill
              theme="snow"
              value={templateHtml}
              onChange={(value) => {
                updateValue(value.toString(), activeModalItem, "value");
              }}
            />
          </>
        ) : null}

        {/* DATE RANGE */}
        {item.parameterType === "dates" ? (
          <div className="d-flex justify-content-around">
            <div className="item">
              <h6>DATA POCZĄTKOWA:</h6>
              <DatePicker
                selected={item.dateFrom ? new Date(item.dateFrom) : null}
                onChange={(date) => {
                  updateValue(date, activeModalItem, "dateFrom");
                }}
                inline
                locale="pl"
                className="calendar"
              />
              {item.dateFrom && (
                <div className="selectedDate">
                  <AiOutlineCalendar color="#00bc8c" />
                  <div>{moment(item.dateFrom).format("YYYY-MM-DD")}</div>
                </div>
              )}
            </div>
            <div className="item">
              <h6>DATA KOŃCOWA:</h6>
              <DatePicker
                selected={item.dateTo ? new Date(item.dateTo) : null}
                onChange={(date) => {
                  updateValue(date, activeModalItem, "dateTo");
                }}
                inline
                locale="pl"
                className="calendar col-6"
              />
              {item.dateTo && (
                <div className="selectedDate">
                  <AiOutlineCalendar color="#e85434" />
                  <div>{moment(item.dateTo).format("YYYY-MM-DD")}</div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {validationError && (
        <div className="mt-4 my-alert-danger">{validationError}</div>
      )}
      {!validationError && (
        <>
          <div
            className="mt-4 my-alert-success"
            onClick={() => {
              setActiveModalItem(activeModalItem + 1);
            }}
          >
            <div>Dane prawidłowe, przejdź dalej</div>
            <GrFormNextLink size={25} />
          </div>
          {completeForm ? (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg bg-orange mt-4"
                onClick={() => {
                  sendOffer();
                }}
              >
                WYŚLIJ
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
