import "./TrackBars.scss";

export const TrackBars = () => {
  return (
    <div className="trackbars-container p-1">
      <input type="range" value={1} min={1} max={10} />
      <input type="range" value={5} min={1} max={10} />
      <input type="range" value={3} min={1} max={10} />
      <input type="range" value={7} min={1} max={10} />
      <input type="range" value={2} min={1} max={10} />
      <input type="range" value={9} min={1} max={10} />
      <input type="range" value={1} min={1} max={10} />
      <input type="range" value={10} min={1} max={10} />
      <input type="range" value={9} min={1} max={10} />
      <input type="range" value={8} min={1} max={10} />
      <input type="range" value={2} min={1} max={10} />
      <input type="range" value={3} min={1} max={10} />
      <input type="range" value={4} min={1} max={10} />
      <input type="range" value={6} min={1} max={10} />
      <input type="range" value={1} min={1} max={10} />
      <input type="range" value={2} min={1} max={10} />
      <input type="range" value={10} min={1} max={10} />
      <input type="range" value={5} min={1} max={10} />
      <input type="range" value={4} min={1} max={10} />
      <input type="range" value={2} min={1} max={10} />
      <input type="range" value={7} min={1} max={10} />
      <input type="range" value={5} min={1} max={10} />
    </div>
  );
};
