import { LeftMenuItem } from "./LeftMenuItem/LeftMenuItem";
import {
  IoHomeOutline,
  IoAlbumsOutline,
  IoCalendarOutline,
  IoClipboardOutline,
} from "react-icons/io5";

export const LeftMenu = () => {
  return (
    <div>
      <div className="d-flex flex-column p-2">
        <LeftMenuItem active={true} icon={<IoHomeOutline />} name="Oferty" />
        <LeftMenuItem
          active={false}
          icon={<IoClipboardOutline />}
          name="Deklaracje"
        />
        <LeftMenuItem active={false} icon={<IoCalendarOutline />} name="Tury" />
        <LeftMenuItem
          active={false}
          icon={<IoAlbumsOutline />}
          name="Kolekcje"
        />
        <LeftMenuItem active={false} icon={<IoHomeOutline />} name="Kwiaty" />
        <LeftMenuItem active={false} icon={<IoHomeOutline />} name="EM" />
        <LeftMenuItem active={false} icon={<IoHomeOutline />} name="Budżety" />
      </div>
    </div>
  );
};
