export const ShopItem = ({ shop, level }: any) => {
  return (
    <div style={{ width: "100%", paddingLeft: level * 15 + "px" }}>
      <div className="d-flex px-2" style={{ padding: "2px 0" }}>
        <input type="checkbox" defaultChecked={shop.checked} />
        <div className="px-2">{shop.name}</div>
      </div>
      {shop.children &&
        shop.children.map((shop: any) => {
          return <ShopItem shop={shop} level={level + 1} />;
        })}
    </div>
  );
};
