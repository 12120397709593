import { createSlice } from "@reduxjs/toolkit";

export const newOfferSlice = createSlice({
  name: "newOffer",
  initialState: {
    typeOffer: "",
    parameters: [],
  },
  reducers: {
    setTypeOffer: (state, action) => {
      state.typeOffer = action.payload;
    },
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
  },
});

export const { setTypeOffer, setParameters } = newOfferSlice.actions;
export default newOfferSlice.reducer;
