import React from "react";
import { CiLight, CiDark } from "react-icons/ci";
import { VscSplitVertical } from "react-icons/vsc";

interface TopBarProps {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
  compareMode: boolean;
  setCompareMode: (compareMode: boolean) => void;
}
export const TopBar = ({
  darkMode,
  setDarkMode,
  compareMode,
  setCompareMode,
}: TopBarProps) => {
  return (
    <div className="d-flex justify-content-between top-bar">
      <div></div>

      <div className="right-side d-flex align-items-center">
        <div
          className="compare-mode-switcher"
          style={{
            marginRight: "4px",
            color: compareMode ? "#fff" : "#777",
            cursor: "pointer",
          }}
          onClick={() => {
            setCompareMode(!compareMode);
          }}
        >
          <VscSplitVertical size={22} />
        </div>
        <div className="darkMode-switcher">
          {darkMode ? (
            <CiLight size={24} onClick={() => setDarkMode(false)} />
          ) : (
            <CiDark size={24} onClick={() => setDarkMode(true)} />
          )}
        </div>
      </div>
    </div>
  );
};
