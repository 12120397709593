import "./LeftMenuItem.scss";

interface LeftMenuItemProps {
  active: boolean;
  icon: JSX.Element;
  name: string;
}

export const LeftMenuItem = ({ active, icon, name }: LeftMenuItemProps) => {
  return (
    <div
      className={`left-menu-item flex-column align-items-center ${
        active ? "active" : ""
      }`}
    >
      {icon}
      <div className="d-flex flex-column item-name">{name}</div>
    </div>
  );
};
