import { useEffect, useRef, useState } from "react";
import "./Timeline.scss";
import Moment from "moment";
import "moment/locale/pl";
import { useDraggable } from "react-use-draggable-scroll";
// import fontColorContrast from "font-color-contrast";
import axios from "axios";
import { IoMdAdd } from "react-icons/io";
import { API_URL } from "../../env";
import { GrNext, GrPrevious } from "react-icons/gr";
import { ContextMenu } from "../ContextMenu/ContextMenu";

interface TimelineProps {
  setShowFormModal: (value: boolean) => void;
  compareMode: boolean;
}

export const Timeline = ({ setShowFormModal, compareMode }: TimelineProps) => {
  const ref = useRef<any>(null);
  const { events } = useDraggable(ref);
  // eslint-disable-next-line
  const [year, setYear] = useState(2022);
  const [todayOffset, setTodayOffset] = useState(0);
  const [centerTimeline, setCenterTimeline] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [addItemButtonHover, setAddItemButtonHover] = useState(false);
  const [contextMenuData, setContextMenuData] = useState<any>({});

  let months = [];
  let weeks = [];
  let itemTopOffset: number = 5;
  const monthsColors = [
    "#b43792",
    "#8d2095",
    "#5d1793",
    "#1553cc",
    "#1e4bc9",
    "#7fc73d",
    "#fffe54",
    "#f9cd46",
    "#f29d38",
    "#f79a39",
    "#ef6f2e",
    "#ec3223",
    "#b43792",
  ];

  const dayColors: any = {
    1: "rgb(234, 239, 105)",
    2: "rgb(238, 101, 233)",
    3: "rgb(147, 234, 99)",
    4: "rgb(227, 169, 87)",
    5: "rgb(106, 149, 229)",
    6: "rgb(156, 102, 218)",
    7: "rgb(234, 28, 28)",
  };

  for (let i = 0; i <= 11; i++) {
    months.push(
      Moment().year(year).month(i).startOf("month").format("YYYY-MM-DD")
    );
  }

  for (let i = 0; i <= 52; i++) {
    weeks.push(
      Moment().year(year).week(i).startOf("week").format("YYYY-MM-DD")
    );
  }

  const getMonth = (date: any) => {
    return Moment(date).format("MMMM YYYY");
  };

  Moment.locale("pl");

  const dayOfYearNumber = Moment().add(0, "day").dayOfYear();
  const dayWidth = 25;
  const firstMondayOfYear = Moment().year(year).week(1).day(0).format("D");

  const getDaysOfMonth = (date: any) => {
    const days = [];
    const daysInMonth = Moment(date).daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days.length;
  };

  useEffect(() => {
    const body = {
      dateFrom: `${year}-01-01T00:00:00.004Z`,
      dateTo: `${year}-12-31T23:59:59.004Z`,
    };
    axios.post(API_URL + "/Offers/getOfferList", body).then((res) => {
      setRowData(res.data.sectors);
    });

    const numberOfDaysToToday = Moment().add(0, "day").dayOfYear();
    setTodayOffset(dayWidth * Number(numberOfDaysToToday));
    setCenterTimeline(true);
  }, [year]);

  useEffect(() => {
    if (centerTimeline && Moment().year(0).format("YYYY") === year.toString()) {
      ref.current.scrollTo({
        left: todayOffset / 10,
        top: 0,
        behavior: "smooth",
      });
      setCenterTimeline(false);
    }
    // eslint-disable-next-line
  }, [todayOffset, centerTimeline]);

  const MonthTemplate = ({ month, startColor, endColor, key }: any) => {
    return (
      <div
        className="timeline-month"
        key={key}
        style={{
          textAlign: "center",
          backgroundImage: `linear-gradient(to right, ${startColor}, ${endColor})`,
          boxShadow: "inset 0px -6px 20px -7px rgba(0, 0, 0, 0.50)",
          // color: fontColorContrast(startColor),
          width: dayWidth * getDaysOfMonth(Moment(month)) + "px",
          maxWidth: dayWidth * getDaysOfMonth(Moment(month)) + "px",
          borderRadius: "4px",
          overflow: "hidden",
          marginRight: "2px",
          backgroundColor: "#222",
        }}
      >
        {getMonth(Moment(month))}
      </div>
    );
  };

  const WeekTemplate = (active: boolean, { week, key }: any) => {
    return (
      <div className="top-sticky" style={{ backgroundColor: "#222" }}>
        {weekSeparator()}
        <div
          key={key}
          className={`timeline-week ${active ? "active-week" : ""}`}
          style={{
            textAlign: "center",
            backgroundColor: "#303030",
            width: dayWidth * 7 + "px",
            borderRadius: "4px",
            marginRight: "2px",
            fontSize: "12px",
          }}
        >
          Tydzień {Moment(week).format("w")}
        </div>
        <div>
          <div className="days d-flex justify-content-around">
            <div
              style={{
                borderTop: `solid 1px ${dayColors[1]}`,
                borderBottom: `solid 1px ${dayColors[1]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              P
              {active && Moment().isoWeekday() === 1 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[2]}`,
                borderBottom: `solid 1px ${dayColors[2]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              W
              {active && Moment().isoWeekday() === 2 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[3]}`,
                borderBottom: `solid 1px ${dayColors[3]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              Ś
              {active && Moment().isoWeekday() === 3 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[4]}`,
                borderBottom: `solid 1px ${dayColors[4]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              C
              {active && Moment().isoWeekday() === 4 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[5]}`,
                borderBottom: `solid 1px ${dayColors[5]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              P
              {active && Moment().isoWeekday() === 5 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[6]}`,
                borderBottom: `solid 1px ${dayColors[6]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              S
              {active && Moment().isoWeekday() === 6 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderTop: `solid 1px ${dayColors[7]}`,
                borderBottom: `solid 1px ${dayColors[7]}`,
                color: "#eee",
              }}
              className="day-name"
            >
              N
              {active && Moment().isoWeekday() === 7 ? (
                <div className="active-day"></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showContextMenu = (e: any, item: any) => {
    e.preventDefault();
    setContextMenuData({
      x: e.clientX,
      y: e.clientY,
      item: item,
      show: true,
    });
  };

  const ItemTemplate = (
    item: any,
    topOffset: number,
    sectorColor: string,
    { key }: any
  ) => {
    const startData = Moment(item.offerStart);
    const endData = Moment(item.offerEnd);
    const diff = endData.diff(startData, "days");
    const firstDayOfYear = Moment().year(2022).month(0).day(0);

    const dayFromStartOfYear = startData.diff(firstDayOfYear, "days");
    const dayStartName = Moment(startData).isoWeekday();
    const dayEndName = Moment(endData).isoWeekday();
    if (diff) {
      return (
        <div
          key={key}
          onClick={(e) => showModal(e, item)}
          onContextMenu={(e) => showContextMenu(e, item)}
          style={{ cursor: "pointer", zIndex: 2 }}
        >
          <div
            className="item"
            style={{
              position: "absolute",
              width: (diff + 1) * dayWidth - 4 + "px",
              textAlign: "center",
              borderRadius: "10px",
              top: topOffset + item.ganttRow * 30 + "px",
              left: (dayFromStartOfYear - 1) * dayWidth + "px",
              height: "20px",
              zIndex: 2000,
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ position: "relative", height: "20px" }}
            >
              <div
                style={{
                  backgroundColor: `${dayColors[dayStartName]}`,
                  color: "black",
                }}
                className="item-dot item-dot-start"
              >
                {Moment(item.offerStart).format("dd")[0]}
              </div>
              <div
                style={{
                  backgroundColor: `#333`,
                  borderBottom: `dashed 1px ${sectorColor}`,
                }}
                className="item-center"
              >
                <div>{item.offerId}</div>
              </div>
              <div
                style={{
                  backgroundColor: `${dayColors[dayEndName]}`,
                  color: "black",
                }}
                className="item-dot item-dot-end"
              >
                {Moment(item.offerEnd).format("dd")[0]}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const weekSeparator = () => {
    return (
      <div
        style={{
          position: "absolute",
          height: "1000px",
          width: "1px",
          borderRight: "1px dashed #444",
          marginTop: "20px",
          zIndex: 0,
          marginLeft: "-1px",
        }}
      ></div>
    );
  };

  const showModal = (event: any, item: any) => {
    return (
      <div className="modal">
        <div className="modal-content"></div>
      </div>
    );
  };

  return (
    <div
      className="d-flex-column"
      style={{ height: compareMode ? "50vh" : "100%", overflow: "hidden" }}
    >
      <div
        className="year-switcher"
        style={{
          zIndex: 1001,
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          position: "sticky",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "6px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setYear(year - 1);
          }}
        >
          <GrPrevious />
        </div>
        <div>
          <strong>{year}</strong>{" "}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setYear(year + 1);
          }}
        >
          <GrNext />
        </div>
      </div>
      {/* TIMELINE */}
      <div
        ref={ref}
        {...events}
        className="timeline-container d-flex"
        style={{ overflow: "hidden", backgroundColor: "#222" }}
      >
        {/* TOP STICKY */}

        <div
          className="top-sticky"
          style={{
            position: "sticky",
            top: 0,
            height: "fit-content",
            zIndex: 2500,
            backgroundColor: "#222",
          }}
        >
          <div
            className="top-sticky"
            style={{
              position: "absolute",
              display: "flex",
              top: "0px",
              paddingTop: "10px",
              backgroundColor: "#222",
            }}
          >
            {months.map((month, key) => {
              return MonthTemplate({
                month,
                startColor: monthsColors[key],
                endColor: monthsColors[key + 1],
                key,
              });
            })}
          </div>
          <div
            className="top-sticky"
            style={{
              position: "absolute",
              display: "flex",
              top: "33px",
              paddingTop: "7px",
              paddingBottom: "3px",
              left: (Number(firstMondayOfYear) - 6) * dayWidth + "px",
              backgroundColor: "#222",
            }}
          >
            {weeks.map((week, key) => {
              const currentWeekNumber = Moment().isoWeek();
              let active = false;
              if (Moment(week).isoWeek() === Moment().isoWeek()) {
                active = true;
              }
              return WeekTemplate(active, { week, key });
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            ></div>
          </div>
        </div>
        {/* SEKTORY */}
        <div className="sectors-container">
          {rowData && rowData.length > 0
            ? rowData.map((item: any, key: number) => {
                let heightSector = 0;
                heightSector = Math.max.apply(
                  Math,
                  item.items.map((o: any) => o.ganttRow)
                );
                return (
                  <>
                    <div
                      key={key}
                      className="sectors"
                      style={{
                        borderLeft: `2px solid ${item.sectorColor}`,
                        minHeight: "fit-content",
                        height: (heightSector + 1) * 30 + "px",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ padding: "5px" }}>
                        <p>{item.sector}</p>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
        <div
          className="items"
          style={{
            position: "absolute",
            top: "100px",

            left: (Number(firstMondayOfYear) - 5) * dayWidth + "px",
          }}
        >
          {rowData.map((item: any, key: number) => {
            const items = item.items;
            const currentSectorHeight = Math.max.apply(
              Math,
              item.items.map((o: any) => o.ganttRow)
            );
            itemTopOffset += (currentSectorHeight + 1) * 30.5;
            return items.map((itemTemp: any, key: number) => {
              return ItemTemplate(
                itemTemp,
                itemTopOffset - (currentSectorHeight + 1) * 30.5,
                item.sectorColor,
                { key }
              );
            });
          })}
        </div>
        <div
          className="add-item-button"
          onMouseEnter={() => {
            setAddItemButtonHover(true);
          }}
          onMouseLeave={() => {
            setAddItemButtonHover(false);
          }}
          style={{
            position: "fixed",
            bottom: "10px",
            right: "60px",
            backgroundImage:
              "linear-gradient(to right, #ff6c37, rgba(211,74,25,1))",
            borderRadius: "35px",
            padding: "10px",
            color: "black",
            cursor: "pointer",
            zIndex: 5000,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "bold",
            height: "50px",
            width: addItemButtonHover ? "100px" : "50px",
            transition: "all 0.15s ease-in-out",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setShowFormModal(true);
            }}
          >
            <IoMdAdd size={30} color="black" />
            <div
              style={{
                transition: "all 0.5s ease-in-out",
                opacity: addItemButtonHover ? 1 : 0,
                display: addItemButtonHover ? "block" : "none",
              }}
            >
              {addItemButtonHover ? "DODAJ" : "DODAJ"}
            </div>
          </div>
        </div>
      </div>
      <ContextMenu
        show={contextMenuData ? contextMenuData.show : false}
        x={contextMenuData ? contextMenuData.x : 0}
        y={contextMenuData ? contextMenuData.y : 0}
        item={contextMenuData ? contextMenuData.item : 0}
        setContextMenuData={setContextMenuData}
      />
    </div>
  );
};
