import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import "./LeftSideComponent.scss";

interface LeftSideComponentProps {
  label: string;
  valid: boolean;
  active: boolean;
  selectedValue?: any;
}

export const LeftSideComponent = ({
  label,
  valid,
  active,
  selectedValue,
}: LeftSideComponentProps) => {
  return (
    <div
      className={`left-side-item ${valid ? "item-success" : "item-false"} ${
        active ? "active-item" : "inactive-item"
      }`}
    >
      <span>{label}</span>
      <div
        className="pill"
        style={{
          backgroundColor: valid ? "#00bc8c" : "#e85434",
          border: `2px solid ${valid ? "#00bc8c" : "#e85434"}`,
        }}
      >
        {valid ? (
          selectedValue ? (
            <strong>{selectedValue}</strong>
          ) : (
            <IoCheckmarkSharp size={20} />
          )
        ) : (
          <IoCloseSharp size={20} />
        )}
      </div>
    </div>
  );
};
