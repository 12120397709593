import { NumberWithSpace } from "../../../Functions/NumberWithSpace";
import { BooleanCell } from "./CustomCells/BooleanCell";
import { NumberCell } from "./CustomCells/NumberCell";

interface MonthsComponentProps {
  columns: any[];
  data: any[];
}

export const MonthsComponent = ({ columns, data }: MonthsComponentProps) => {
  let sumOfObjects = [];

  data.forEach((item) => {
    sumOfObjects.push(item["C"] + item["B"]);
  });

  const max = Math.max(...sumOfObjects);

  const getOb = (promo: number, nonPromo: number) => {
    return <NumberCell promo={promo} nonPromo={nonPromo} max={max} />;
  };

  const getAverage = () => {
    let sumAvarage: number = 0;
    let sumSales: number = 0;
    data.forEach((item) => {
      sumAvarage += Number(item["F"]);

      sumSales += Number(item["C"] + item["B"]);
    });

    return (sumAvarage / sumSales) * 100;
  };

  return (
    <div className="p-1">
      <table className="table table-dark table-bordered table-hover">
        <thead>
          <th style={{ textAlign: "right" }}>M</th>
          <th style={{ textAlign: "right" }}>OB</th>
          <th style={{ textAlign: "right" }}>IL</th>
          <th style={{ textAlign: "right" }}>ŚCS</th>
          <th style={{ textAlign: "right" }}>M%</th>
        </thead>
        {data.map((item: any, key: number) => {
          return (
            <tr key={key} style={{ position: "relative" }}>
              <td style={{ textAlign: "right" }}>{item["A"]}</td>
              {getOb(item["C"], item["B"])}
              <td style={{ textAlign: "right" }}>
                {NumberWithSpace(Number(item["E"] + item["D"]))}
              </td>
              <td style={{ textAlign: "right" }}>
                {((item["C"] + item["B"]) / (item["E"] + item["D"])).toFixed(2)}
              </td>
              <BooleanCell
                value={
                  Number(
                    ((item["F"] / (item["C"] + item["B"])) * 100).toFixed(2)
                  ) > getAverage()
                    ? true
                    : false
                }
                cellValue={(
                  (item["F"] / (item["C"] + item["B"])) *
                  100
                ).toFixed(2)}
              />
            </tr>
          );
        })}
      </table>
    </div>
  );
};
