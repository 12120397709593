import { GoPrimitiveDot } from "react-icons/go";

interface BooleanCellProps {
  value: boolean;
  cellValue: string;
}

export const BooleanCell = ({ value, cellValue }: BooleanCellProps) => {
  return (
    <td
      className="d-flex"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      <div className="d-flex align-items-center p-0">
        {cellValue}%
        <GoPrimitiveDot color={value ? "#00D108" : "#ff0000"} />
      </div>
    </td>
  );
};
